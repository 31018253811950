
.aiResponse {
  width: 800px;
  text-align: left;
  background-color: #252424;
  color: #ffffff;
  padding: 30px;
  border-radius: 20px;
  margin-bottom: 40px;
  font-family: "Roboto", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-size: 12pt;
  line-height: 1.6em;
  font-style: normal;
}