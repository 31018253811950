@import url('https://fonts.googleapis.com/css2?family=Madimi+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
  background-color: #edf2fb;
  margin: 0;
  padding: 0;
  height: 100%;
  margin: 0;
}

/* .App {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
} */

.contentContainer {
  flex: 1;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


/* Bento Box */

.bentoBox {
  background-color: #FFFFFF;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 0px auto 0px auto;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.25);
  transform: perspective(1000px) rotateX(5deg);
  width: calc(26vh + 26vh + 32vh + 6px);
  z-index: 1;
}

.bentoBoxDepth {
  z-index: -2;
  padding: 0;
  left: 0;
  width: calc(26vh + 26vh + 32vh + 20px);
  height: 60px;
  background-color: #ececec;
  border-radius: 0 0 20px 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  transform: translateY(-20px);
  background-image: linear-gradient(to right,
      #dedede 0%,
      white 20px,
      white calc(100% - 20px),
      #dedede 100%);
}

.bentoBoxRow {
  display: flex;
  flex-direction: row;
  transform: translateZ(30px);
}

.bentoBoxRow:first-child {
  margin-bottom: 20px;
}

.bentoElement {
  margin-right: 20px;
  border-radius: 10px;
  color: #FFFFFF;
  transition: transform 0.3s ease-in-out;
  /* cursor: pointer; */
  text-align: left;
  transform: translateZ(60px);
  position: relative;
}

.bentoElement:hover {
  transform: scale(1.04) translateZ(80px);
}

.bentoElement:last-child {
  margin-right: 0;
}

.bentoElementStandard {
  width: 26vh;
  height: 26vh;
}

.bentoElementWide {
  width: 32vh;
  height: 26vh;
}

.bentoNavy {
  background-color: #2b2d42;
}

@keyframes shine {
  from {
    left: -150%; /* Start from the left, outside of the view */
  }
  to {
    left: 150%; /* End at the right, moving the shine across */
  }
}

.bentoYellow::before {
  content: '';
  position: absolute;
  top: 0;
  left: -150%; /* Start outside of the element */
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  transform: skewX(-30deg);
  animation: shine 1s ease-out forwards 140ms; /* Use the animation, duration, easing, and fill mode */
}

.bentoYellow {
  overflow: hidden; 
  position: relative;
  background-color: #ff9f1c; /* Your existing background-color */
  /* Include other styles for .bentoYellow here */
}

.bentoElement::after {
  content: '';
  position: absolute;
  background-color: #FFFFFF10;
  top: 10%;
  left: 4%;
  right: 0%;
  bottom: 0%;
  border-radius: 5px 0 0 0;
  box-shadow: inset 0 4px 10px -2px rgba(0, 0, 0, 0.10);

}

.bentoElement1 {
  background-image: linear-gradient(to left,
  rgba(0, 0, 0, 0) calc(95%),
  rgba(0, 0, 0, 0.14) 100%);

      /* background-image: linear-gradient(to left,
      rgba(0, 0, 0, 0.7) 5%,
      rgba(0, 0, 0, 0) 100%); */

}

.bentoElement1::after {
  top: 10%;
  left: 4%;
  right: 0%;
  bottom: 0%;
  border-radius: 5px 0 0 0;
}

.bentoElement2::after {
  top: 10%;
  left: 0%;
  right: 0%;
  bottom: 0%;
  border-radius: 0px 0 0 0;
}

.bentoElement3 {
  background-image: linear-gradient(to right,
  rgba(0, 0, 0, 0) calc(100% - 6%),
  rgba(0, 0, 0, 0.14) 100%);
}

.bentoElement3::after {
  top: 10%;
  left: 0%;
  right: 4%;
  bottom: 0%;
  border-radius: 0 5px 0 0;
}

.bentoElement4 {
  background-image: linear-gradient(to left,
  rgba(0, 0, 0, 0) calc(94%),
  rgba(0, 0, 0, 0.14) 100%);
}

.bentoElement4::after {
  top: 10%;
  left: 4%;
  right: 0%;
  bottom: 0%;
  border-radius: 5px 0 0 0;
}

.bentoElement5::after {
  top: 10%;
  left: 0%;
  right: 0%;
  bottom: 0%;
  border-radius: 0px 0 0 0;
}

.bentoElement6 {
  background-image: linear-gradient(to right,
  rgba(0, 0, 0, 0) calc(100% - 6%),
  rgba(0, 0, 0, 0.14) 100%);
}

.bentoElement6::after {
  top: 10%;
  left: 0%;
  right: 4%;
  bottom: 0%;
  border-radius: 0 5px 0 0;
}

.bentoElementHeader {
  font-size: 18pt;
  font-family: "Madimi One", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin: 0;
  padding: 30px 20px 20px 20px;
}

.bentoElementHeaderVi {
  font-size: 18pt;
  font-family: "Protest Strike", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin: 0;
  padding: 30px 20px 20px 20px;
}

.bentoElementHeaderBold {
  font-size: 18pt;
  font-family: "Open Sans", sans-serif;
  font-weight: 800;
  font-style: normal;
  margin: 0;
  padding: 30px 20px 20px 20px;
}

.bentoElementDetail {
  font-size: 14pt;
  font-family: "Madimi One", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin: 0;
  padding: 0px 20px 0px 20px;
}

.linkReset {
  color: #ffffff;
  text-decoration: none;
}


/* Loading Spinner */

.spinner {
  border: 8px solid #2b2d42;
  border-top: 8px solid #ffffff;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  animation: spin 2s linear infinite;
}

.spinnerSmall {
  border: 4px solid #2b2d42;
  border-top: 4px solid #ffffff;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  animation: spin 2s linear infinite;
}

.spinnerSmallWhite {
  border: 4px solid #ffffff;
  border-top: 4px solid #ffffff60;
  opacity: 0.8;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}






/* Flat style for the slider */
.range-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  background: #2b2d42; /* Tailwind's gray-600 */
  cursor: pointer;
  border-radius: 50%;
}

.range-slider::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  background: #dedede; /* Tailwind's gray-400 */
  cursor: pointer;
}

.range-slider::-moz-range-thumb {
  width: 16px;
  height: 16px;
  background: #2b2d42;
  cursor: pointer;
  border-radius: 50%;
}

.range-slider::-moz-range-track {
  width: 100%;
  height: 4px;
  background: #dedede;
  cursor: pointer;
}

.range-slider::-moz-range-progress {
  background: #2b2d42; /* Tailwind's gray-500 */
}

.range-slider::-webkit-slider-thumb {
  margin-top: -6px; /* Adjust thumb position to match the track */
}

/* For IE */
.range-slider::-ms-thumb {
  width: 16px;
  height: 16px;
  background: #2b2d42;
  cursor: pointer;
  border-radius: 50%;
}

.range-slider::-ms-track {
  width: 100%;
  height: 4px;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

.range-slider::-ms-fill-lower {
  background: #dedede;
}

.range-slider::-ms-fill-upper {
  background: #dedede;
}



.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 200ms ease-in 400ms; /* Starts after 200ms delay, lasts for 300ms */
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 200ms ease-in; /* Completes instantly from a visual perspective */
}


