@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Madimi+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Shantell+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lilita+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Protest+Strike&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes gradient-bg {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@layer utilities {
  .animated-gradient-bg {
    background: linear-gradient(45deg, #f472b6, #9333ea, #3b82f6, #6ee7b7);
    background-size: 400% 400%;
    animation: gradient-bg 6s ease infinite;
  }
}






@keyframes fadeInScaleUp {
  from {
    opacity: 0;
    transform: scale(0.6);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeOutScaleDown {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(1.4);
  }
}

.question.enter {
  animation: fadeInScaleUp 0.5s forwards;
}

.question.leave {
  animation: fadeOutScaleDown 0.5s forwards;
}

.option {
  margin-top: 10px;
  padding: 10px;
  border: none;
  background-color: lightblue;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.option.disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
