@import url('https://fonts.googleapis.com/css2?family=Madimi+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lilita+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

body {
  background-color: #edf2fb;
  margin: 0;
  padding: 0;
}

.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contentContainerHome {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}


.homeLanguageName {
  font-size: 12pt;
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: 800;
  margin: 0;
}


.tierBox h3 {
  font-family: "Madimi One", sans-serif;
  font-size: 18pt;
  font-weight: 400;
  font-style: normal;
}

.tierFeature {
  /* font-size: 18pt; */
  font-family: "M PLUS Rounded 1c", sans-serif;
  /* font-weight: 800; */
}

.markedByAIChip {
  background-color: #2b2d42;
  color: #FFFFFF;
  font-family: "Madimi One", sans-serif;
  font-weight: 400;
  padding: 4px 12px;
  border-radius: 10px;
  font-size: 10pt;
  margin-left: 6px;
}

.featureContainer {
  display: flex;
  flex-direction: row;
  width: 800px;
  justify-content: space-between;
  align-items: center;
}

.featureTextContainer {
  text-align: left;
  width: 400px;
}

.featureHeadline {
  font-family: "Lilita One", sans-serif;
  font-size: 42pt;
  font-weight: 100;
  font-style: normal;
  margin: 0;
}

.featureDescription {
  font-size: 18pt;
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: 800;
  /* font-style: normal; */
  margin: 10px 0;
}


@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.gradient-text {
  background: linear-gradient(45deg, #6ee7b7, #3b82f6, #9333ea, #f472b6);
  background-size: 400% 400%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradient 8s ease infinite;
}

@keyframes gradient-bg {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.animated-gradient-bg {
  background: linear-gradient(45deg, #f472b6, #9333ea, #3b82f6, #6ee7b7);
  background-size: 400% 400%;
  animation: gradient-bg 6s ease infinite;
}






/* Bento Box */

.mobileBentoBox {
  background-color: #FFFFFF;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.25);
  transform: perspective(1000px) rotateX(5deg);
  max-width: calc(100vw - 40px);
  z-index: 1;
}

.mobileBentoBoxDepth {
  z-index: -2;
  padding: 0;
  left: 0;
  width: calc(100vw - 32px);
  height: 50px;
  background-color: #ececec;
  border-radius: 0 0 20px 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  transform: translateY(-20px);
  background-image: linear-gradient(to right,
      #dedede 0%,
      white 20px,
      white calc(100% - 20px),
      #dedede 100%);
}

.mobileBentoBoxRow {
  display: flex;
  flex-direction: row;
  transform: translateZ(30px);
}

.mobileBentoBoxRow:first-child {
  margin-bottom: 10px;
}

.mobileBentoBoxRow:last-child {
  margin-top: 10px;
}

.mobileBentoElement {
  margin-right: 10px;
  border-radius: 10px;
  color: #FFFFFF;
  outline: 1px solid transparent;
  cursor: pointer;
  text-align: left;
  transform: translateZ(60px);
  position: relative;
}

.mobileBentoElementRight {
  margin-right: 0;
}

.mobileBentoElementStandard {
  width: 40vw;
  height: 160px;
  max-height: 22vh;
}

.mobileBentoElementWide {
  width: 50vw;
  height: 160px;
  max-height: 22vh;
}

.mobileBentoNavy {
  background-color: #2b2d42;
}

@keyframes shine {
  from {
    left: -150%;
  }
  to {
    left: 150%;
  }
}

.mobileBentoElementLeft {
  background-image: linear-gradient(to left,
  rgba(0, 0, 0, 0) calc(95%),
  rgba(0, 0, 0, 0.14) 100%);
}

.mobileBentoElementLeft::after {
  top: 10%;
  left: 4%;
  right: 0%;
  bottom: 0%;
  border-radius: 5px 0 0 0;
}

.mobileBentoElementRight {
  background-image: linear-gradient(to right,
  rgba(0, 0, 0, 0) calc(100% - 6%),
  rgba(0, 0, 0, 0.14) 100%);
}

.mobileBentoElementRight::after {
  top: 10%;
  left: 0%;
  right: 4%;
  bottom: 0%;
  border-radius: 0 5px 0 0;
}

.mobileBentoYellow {
  background-color: #ff9f1c;
  overflow: hidden; 
  position: relative;
}

.mobileBentoYellow::before {
  content: '';
  position: absolute;
  top: 0;
  left: -150%; /* Start outside of the element */
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  transform: skewX(-30deg);
  animation: shine 1s ease-out forwards 140ms;
}

.mobileBentoElementLeft::after {
  content: '';
  position: absolute;
  background-color: #FFFFFF10;
  top: 10%;
  left: 4%;
  right: 0%;
  bottom: 0%;
  border-radius: 5px 0 0 0;
  box-shadow: inset 0 4px 10px -2px rgba(0, 0, 0, 0.10);
}

.mobileBentoElementRight::after {
  content: '';
  position: absolute;
  background-color: #FFFFFF10;
  top: 10%;
  left: 0%;
  right: 4%;
  bottom: 0%;
  border-radius: 0 5px 0 0;
  box-shadow: inset 0 4px 10px -2px rgba(0, 0, 0, 0.10);
}

.mobileBentoElementHeader {
  font-size: 16pt;
  font-family: "Madimi One", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin: 0;
  padding: 16px 12px 8px 12px;
}

.mobileBentoElementHeaderVi {
  font-size: 16pt;
  font-family: "Protest Strike", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin: 0;
  padding: 16px 12px 8px 12px;
}

.mobileBentoElementHeaderBold {
  font-size: 16pt;
  font-family: "Open Sans", sans-serif;
  font-weight: 800;
  font-style: normal;
  margin: 0;
  padding: 16px 12px 8px 12px;
}

.mobileBentoElementDetail {
  font-size: 12pt;
  font-family: "Madimi One", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin: 0;
  padding: 0px 12px 0px 12px;
}

.linkReset {
  color: #ffffff;
  text-decoration: none;
}