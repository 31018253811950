@import url('https://fonts.googleapis.com/css2?family=Madimi+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lilita+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

body {
  background-color: #edf2fb;
  margin: 0;
  padding: 0;
}

.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contentContainerHome {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}


.homeLanguageName {
  font-size: 12pt;
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: 800;
  margin: 0;
}


.tierBox h3 {
  font-family: "Madimi One", sans-serif;
  font-size: 18pt;
  font-weight: 400;
  font-style: normal;
}

.tierFeature {
  /* font-size: 18pt; */
  font-family: "M PLUS Rounded 1c", sans-serif;
  /* font-weight: 800; */
}

.markedByAIChip {
  background-color: #2b2d42;
  color: #FFFFFF;
  font-family: "Madimi One", sans-serif;
  font-weight: 400;
  padding: 4px 12px;
  border-radius: 10px;
  font-size: 10pt;
  margin-left: 6px;
}

.featureContainer {
  display: flex;
  flex-direction: row;
  width: 800px;
  justify-content: space-between;
  align-items: center;
}

.featureTextContainer {
  text-align: left;
  width: 400px;
}

.featureHeadline {
  font-family: "Lilita One", sans-serif;
  font-size: 42pt;
  font-weight: 100;
  font-style: normal;
  margin: 0;
}

.featureDescription {
  font-size: 18pt;
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: 800;
  /* font-style: normal; */
  margin: 10px 0;
}


@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.gradient-text {
  background: linear-gradient(45deg, #6ee7b7, #3b82f6, #9333ea, #f472b6);
  background-size: 400% 400%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradient 8s ease infinite;
}

@keyframes gradient-bg {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.animated-gradient-bg {
  background: linear-gradient(45deg, #f472b6, #9333ea, #3b82f6, #6ee7b7);
  background-size: 400% 400%;
  animation: gradient-bg 6s ease infinite;
}