.container {
  perspective: 1000px;
  width: 360px;
  max-width: 90vw;
  height: 240px;
  background-color: transparent;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0);
}

.card {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.6s;
  cursor: pointer;
  animation: none;
}

.card.flipped {
  transform: rotateY(180deg);
}

.container.enter {
  animation: fadeInScaleUp 0.5s forwards;
}

.container.leave {
  animation: fadeOutScaleDown 0.5s forwards;
}

.front, .back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  color: #1f1f1f;
  border-radius: 20px;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15);
}

.front {
  background-color: #FFFFFF;
}

.back {
  background-color: #FFFFFF;
  transform: rotateY(180deg);
}


.card.not-animated {
  transition: none;
}

.card.flipped.animated {
  transform: rotateY(180deg);
  transition: transform 0.6s;
}